<template>
  <div class="iq-card wallet-settings">
    <div class="iq-card-body position-relative w-100 d-flex gap_2" :class="{'flex-column': editMode}">
      <h4 class="wallet-settings-box-title">{{$t('payment.pin_code')}}</h4>
      <div v-if="!editMode" class="wallet-settungs-box">
        <h5 class="wallet-settungs-box-info wallet-settungs-box-info-pin">******</h5>
      </div>
      <div v-else class="wallet-settungs-box">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form class="position-relative form-top" @submit.prevent="handleSubmit(changePin)">
            <b-row>
              <b-col cols="12">
                <div class="position-relative mb-4">
                  <input-form
                      name="pin"
                      :label="$t('payment.current_pin_code')"
                      validate="required|digits:5|numeric"
                      :placeholder="$t('payment.current_pin_code')"
                      v-model="pin.current_PIN"
                      class="d-flex align-items-center flex-wrap gap_2 pin-input"
                  />
                </div>
                <div class="position-relative mb-4">
                  <input-form
                      name="pin"
                      validate="required|digits:5|numeric"
                      :label="$t('payment.new_pin_code')"
                      :placeholder="$t('payment.new_pin_code')"
                      v-model="pin.new_PIN"
                      class="d-flex align-items-center flex-wrap gap_2 pin-input"
                  />
                </div>
                <div class="position-relative mb-4">
                  <input-form
                      name="pin"
                      validate="required"
                      :label="$t('payment.confirm_pin_code')"
                      :placeholder="$t('payment.confirm_pin_code')"
                      v-model="pin.confirm_pin"
                      class="d-flex align-items-center flex-wrap gap_2 pin-input"
                  />
                </div>
                <div>
                  <b-button variant="primary" class="save-setting-btn w-100" type="submit">{{ $t('auth.save') }}</b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </div>
      <b-button class="position-absolute border-bottom-0 edit-btn" @click="toggleEditMode">{{ $t('payment.edit') }}</b-button>
    </div>
  </div>
</template>

<script>
import websiteServices from '../services/userProfile'
import { core } from '@/config/pluginInit'

export default {
  data () {
    return {
      editMode: false,
      pin: {
        current_PIN: '',
        new_PIN: '',
        confirm_pin: ''
      }
    }
  },
  methods: {
    toggleEditMode () {
      this.editMode = !this.editMode
    },
    changePin () {
      websiteServices.editPinCode(this.pin).then(res => {
        core.showSnackbar('success', res.data.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.iq-card {
  border-radius: 2px !important;
}
.wallet-settungs-box-info-pin{
  color: #00443D !important;
}
.wallet-settings{
  position: relative;
}
.edit-btn{
  color: #00443D !important;
  background: transparent !important;
  border: 0 !important;
  position: absolute;
  left: 0;
  top: 30px;
  transform: translateY(-50%);
  font-size: 1.2rem !important;
  &:lang(en){
    left: auto !important;
    right: 0 !important;
  }
}
.save-setting-btn{
  border-radius: 2px !important;
}
</style>
